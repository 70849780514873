<template>
    <div class="player" :style="{top: `${pixels}px`, zIndex: index}">
        <div class="rank">
            <span>#{{ rank }}</span>
        </div>
        <div class="name">
            <img :src="image" :alt="name">
            <span>{{ name }}</span>
        </div>
        <div class="score">
            <span>{{ score || "???" }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "BoardItem",
    props: {
        rank: {
            type: Number,
            default: 0
        },
        player: {
            type: Object,
            default: {
                uuid: "",
                name: "",
                score: 0
            }
        }
    },
    computed: {
        name() {
            return this.player?.name ?? null
        },
        uuid() {
            return this.player?.uuid ?? null
        },
        score() {
            return this.player?.score ?? null
        },
        image() {
            return this.player?.avatar?.image ?? null
        },
        pixels() {
            return this.rank * 50
        },
        index() {
            return 500 - this.rank
        },
    }
}
</script>
<style scoped>
</style>
