<template>
    <div id="leader-board">
        <div class="container">
            <div class="wrapper">
                <h1>Leaderboard</h1>
                <div class="board" :style="{height: `${(players.length * 50) + 50}px`}">
                    <div class="player" :style="{top: `0px`}">
                        <div class="rank">
                            <span>Rank</span>
                        </div>
                        <div class="name">
                            <span>Player</span>
                        </div>
                        <div class="score">
                            <span>Score</span>
                        </div>
                    </div>
                    <board-item v-for="(player, index) in players"
                                :key="index"
                                :player="player"
                                :rank="rankings[player.uuid]"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {gql} from "apollo-boost";
import BoardItem from "@/components/leaderboard/BoardItem";

export default {
    name: "Leaderboard",
    components: {BoardItem},
    data: () => ({
        players: [],
        error: false
    }),
    apollo: {
        players: {
            query: gql`query players {
                players {
                    uuid
                    name
                    score
                    avatar {
                        image
                    }
                }
            }`,
            pollInterval: 5000,
            fetchPolicy: 'cache-and-network'
        }
    },
    computed: {
        sorted() {
            const players = JSON.parse(JSON.stringify(this.players))
            return players.sort((a, b) => {
                return (a?.score ?? 0) < (b?.score ?? 0)? 1 : -1
            })
        },
        rankings() {
            let rankings = {}
            for (const p in this.sorted) {
                const player = this.sorted[p]
                rankings[player.uuid] = Number.parseInt(p) + 1
            }
            return rankings
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/views/Leaderboard"
</style>
